import Eventbus from '@libs/eventbus'
import { City } from '@libs/types/City.ts'
import { Box, Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material'
import { setCheckedCities, setIncludeBussing } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useCallback } from 'react'

const CitiesFilter: React.FC = () => {
  const allCities = useAppSelector((state) => state.metro.allCities)
  const selectedMetro = useAppSelector((state) => state.metro.selectedMetro)
  const selectedMetroCities: City[] = allCities.filter((city: City) => city.metro === selectedMetro?.id)
  const dispatch = useAppDispatch()

  const checkedCities: string[] = useAppSelector((state) => state.campFilters.filters.cities.checkedCities) || []
  const bussing: boolean = useAppSelector((state) => state.campFilters.filters.cities.includeBussing || false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    if (event.target.checked) {
      dispatch(setCheckedCities([...new Set([...checkedCities, event.target.value])]))
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Cities Filter Checked',
        value: event.target.value,
      })
    } else {
      dispatch(setCheckedCities(checkedCities.filter((city) => city !== event.target.value)))
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Cities Filter UnChecked',
        value: event.target.value,
      })
    }
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }
  const handleBussingChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setIncludeBussing(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Cities Include Bussing Changed',
      value: event.target.checked ? 'on' : 'off',
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const isChecked = useCallback(
    (city: City) => {
      return checkedCities.indexOf(city.full_name) !== -1
    },
    [checkedCities]
  )

  return (
    <Box>
      <Box
        maxHeight={420}
        className="custom-scrollbar"
        sx={{
          overflowY: 'auto',
        }}
        data-testid={'city-filter-list-element'}
      >
        {/*<Typography color={'#2D3648'} mb={2} fontFamily={'Inter'} fontSize={'14px'} lineHeight={'20px'}>*/}
        {/*{bussing ? <>*/}
        {/*  Excludes camps with bussing locations in selected cities. To include, change the bussing locations setting*/}
        {/*  under the Distance filter.</> :*/}
        {/*  <>*/}
        {/*    Includes camps with bussing locations in selected cities. To exclude, change the bussing locations setting under the Distance filter.*/}
        {/*  </>*/}
        {/*}*/}
        {/*</Typography>*/}
        {selectedMetroCities.map((city, index) => (
          <Box key={index}>
            <FormGroup>
              <FormControlLabel
                data-testid={'city-filter-list-element'}
                label={city.full_name}
                control={<Checkbox value={city.full_name} onChange={handleChange} checked={isChecked(city)} />}
              />
            </FormGroup>
          </Box>
        ))}
      </Box>
      <FormControlLabel
        sx={{ mb: 2, pt: 4 }}
        control={<Switch onChange={handleBussingChange} sx={{ m: 1 }} checked={bussing} />}
        label="Include camps with bussing locations in selected cities"
      />
    </Box>
  )
}

export default CitiesFilter
