import { City } from '@libs/types/City.ts'
import { Metro } from '@libs/types/Metro.ts'
import { Provider } from '@libs/types/Provider.ts'
import { Schoolbreak } from '@libs/types/Schoolbreak.ts'
import { createSlice } from '@reduxjs/toolkit'

interface MetroSliceState {
  metros: Metro[]
  allCities: City[]
  selectedMetro: Metro | null
  sessionStorageSchoolBreaksList: Schoolbreak[]
  sessionStorageProviderList: Provider[]
}

export const initialState: MetroSliceState = {
  metros: [],
  allCities: [],
  selectedMetro: null,
  sessionStorageSchoolBreaksList: [],
  sessionStorageProviderList: [],
}

export const metroReducer = createSlice({
  name: 'metro',
  initialState,
  reducers: {
    getSelectedMetro: () => {},
    setSelectedMetro: (state, action) => {
      state.selectedMetro = action.payload
    },
    getMetros: () => {},
    setMetros: (state, action) => {
      state.metros = action.payload
    },
    getCities: () => {},
    setCities: (state, action) => {
      state.allCities = action.payload
    },
    setSessionStorageSchoolBreaksList: (state, action) => {
      state.sessionStorageSchoolBreaksList = action.payload
      sessionStorage['api:schoolbreaks'] = JSON.stringify(action.payload)
    },
    getSessionStorageSchoolBreaksList: () => {},
    setSessionStorageProviderList: (state, action) => {
      state.sessionStorageProviderList = action.payload
      sessionStorage['api:providerList'] = JSON.stringify(action.payload)
    },
  },
})

export const {
  getMetros,
  setMetros,
  getCities,
  setCities,
  getSelectedMetro,
  setSelectedMetro,

  getSessionStorageSchoolBreaksList,
  setSessionStorageSchoolBreaksList,
  setSessionStorageProviderList,
} = metroReducer.actions

export default metroReducer.reducer
