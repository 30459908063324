import metroSaga from '@store/metro/metroSaga.ts'
import { all, call } from 'redux-saga/effects'

import campFiltersSaga from './campFilters/campFiltersSaga'
import campPageSaga from './campPage/campPageSaga'
import campSearchSaga from './campSearch/campSearchSaga'
import globalSaga from './global/globalSaga'
import schoolBreaksSaga from './schoolBreaks/schoolBreaksSaga'
import userListSaga from './userList/userListSaga.ts'

export default function* rootSaga() {
  const sagas = [globalSaga, campPageSaga, campFiltersSaga, campSearchSaga, schoolBreaksSaga, userListSaga, metroSaga]
  yield all(sagas.map((saga) => call(saga)))
}
