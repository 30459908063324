import { colors } from '@config/theme'
import { api } from '@libs/api'
import { Box, LinearProgress, useMediaQuery } from '@mui/material'
import { Text } from '@stories/Typography/Text/Text'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import FavoritesItem from '../components/FavoritesItem'
import { Camp } from '../libs/types/Camp.ts'

const SharableFavorites = () => {
  useEffect(() => {
    mixpanel.track_pageview({ page: 'SharableFavorites' })
  }, [])

  const isSmallScreen = useMediaQuery('(max-width:600px)')

  const { sharableId } = useParams()
  if (!sharableId) {
    return null
  }

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<{ name: string; camps: number[]; camps_decorated: Camp[] }>({
    name: '',
    camps: [],
    camps_decorated: [],
  })
  useEffect(() => {
    if (!sharableId) {
      window.location.href = '/'
      return
    }

    let isMounted = true

    const fetchSharableData = async () => {
      try {
        const response = await api.getSharableListById(sharableId)
        const { data: selectedCamps } = await api.getCampsForSpecificIds(response.data.camps)

        if (isMounted) {
          setData({ ...response.data, camps_decorated: selectedCamps })
          setIsLoading(selectedCamps.length === 0)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchSharableData()

    return () => {
      isMounted = false // Cleanup on unmount
    }
  }, [sharableId])

  if (!data) {
    return null
  }
  return (
    <Box sx={{ padding: isSmallScreen ? '3rem 1.5rem' : '3rem 2rem' }}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        sx={{ borderBottom: `1px solid ${colors.brandGray50}` }}
        pb={3}
      >
        <Text
          variant={'h1'}
          fontFamily="Ubuntu"
          fontSize={isSmallScreen ? '24px' : '30px'}
          fontWeight="bold"
          color={colors.brandGray900}
          style={{ display: 'flex', textTransform: 'capitalize' }}
        >
          {data.name}&nbsp;
        </Text>
      </Box>
      {isLoading && <LinearProgress />}
      <Box width={'100%'} overflow={'auto'}>
        {data?.camps_decorated &&
          data?.camps_decorated?.map((item) => (
            <FavoritesItem key={item.id} item={item} list_id={sharableId} hideItemMenu={true} />
          ))}
      </Box>
    </Box>
  )
}

export default SharableFavorites
