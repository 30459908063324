import { colors } from '@config/theme'
import { findEntityById } from '@decorators/findEntityById.ts'
import { api } from '@libs/api'
import { Metro } from '@libs/types/Metro.ts'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { setCampList, setFilteredCampList } from '@store/campSearch/campSearchSlice.ts'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setSelectedMetro } from '@store/metro/metroSlice.ts'
import { getUserList } from '@store/userList/userListSlice'
import { Button } from '@stories/Theme/Button/Button.tsx'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { CitiesMarquee } from '../components/CitiesMarquee/CitiesMarquee.tsx'
import redis from '../libs/lockr.ts'
import ChooseMetroSkeleton from './ChooseMetro.skeleton.tsx'

const ChooseMetro = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const metros = useAppSelector((state) => state.metro.metros)
  const selectedMetro = useAppSelector((state) => state.metro.selectedMetro)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const validateUserMetro = async () => {
      try {
        mixpanel.track_pageview({ page: 'ChooseMetro' })

        const isLoggedIn = api.isUserLoggedIn()
        if (!isLoggedIn) {
          navigate('/register')
          return
        }

        if (selectedMetro) {
          navigate('/')
          return
        }

        api
          .updateUserFromDB()
          .then((updatedUser) => {
            if (updatedUser.metro !== null && updatedUser.metro !== undefined) {
              if (metros.length) {
                dispatch(setSelectedMetro(findEntityById<Metro>(updatedUser.metro, metros)))
                navigate('/')
                return
              }
            }
          })
          .finally(() => {
            if (metros.length > 0) {
              setIsLoading(false)
            }
          })
      } catch (error) {
        console.error('An error occurred:', error)
      } finally {
        dispatch(getUserList())
      }
    }

    validateUserMetro()
  }, [metros])

  const handleClick = (metro: Metro) => {
    api
      .setUserMetro(metro.id)
      .then(() => {
        dispatch(setCampList([]))
        dispatch(setFilteredCampList([]))
        dispatch(setSelectedMetro(metro))
        const redirectUrl = redis.get('redirectUrl')
        if (redirectUrl && typeof redirectUrl === 'string') {
          window.location.href = redirectUrl
          return
        }
        return navigate('/')
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom my={6}>
        Choose your location
      </Typography>
      {isLoading ? (
        <ChooseMetroSkeleton />
      ) : (
        <>
          <Grid container columnSpacing={{ xs: 0, sm: 4 }} rowSpacing={4} data-testid={'metro_title'}>
            {metros
              .filter((metro) => metro.active)
              .map((metro) => (
                <Grid key={metro.id} item xs={12} sm={12} md={6} lg={4}>
                  <StyledCard>
                    <StyledMainImage
                      data-testid={`metro-${metro.id}-image`}
                      src={metro.image_url}
                      alt={metro.full_name}
                      loading={'lazy'}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src =
                          'https://camperoni-com-prod-cities-images.nyc3.cdn.digitaloceanspaces.com/default-city.jpg'
                      }}
                    />
                    <StyledCardContent>
                      <CitiesMarquee
                        metro={metro}
                        customWidth={'80%'}
                        customFontWeight={400}
                        customFontSize={'1rem'}
                        testId={`metro-${metro.id}-scrolling-city-value-`}
                      />
                      <StyledCardTitle data-testid={`metro-title-value-${metro.id}`}>{metro.full_name}</StyledCardTitle>
                      <CampCounterBox>
                        <Box sx={{ flex: 1 }}>
                          <StyledTitle data-testid={`${metro.id}-camps`}>{metro.camps_count}</StyledTitle>
                          <Counter>camps</Counter>
                        </Box>
                        <FromText>from</FromText>
                        <Box sx={{ flex: 1 }}>
                          <StyledTitle data-testid={`${metro.id}-providers`}>{metro.providers_count}</StyledTitle>
                          <Counter>camp providers</Counter>
                        </Box>
                      </CampCounterBox>
                      <ButtonContainer>
                        <Button
                          fullWidthOnSmallScreen
                          title={'Select'}
                          rounded={true}
                          style={{ padding: '0.5rem 1.2rem', height: '40px' }}
                          size="small"
                          titleStyle={{ fontSize: '1rem' }}
                          onClick={() => handleClick(metro)}
                        />
                      </ButtonContainer>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </Container>
  )
}

export default ChooseMetro

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;

  @media${({ theme }) => theme.device['sm']} {
    padding: 2rem;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const StyledCardContent = styled(CardContent)`
  padding: 1rem !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
const StyledCardTitle = styled(Typography)`
  font-family: 'Ubuntu', sans-serif !important;
  color: ${colors.brandGray900};
  font-size: 2.25rem !important;
  line-height: 2.75rem !important;
  font-weight: 700 !important;
  letter-spacing: -0.1125rem;
`

const StyledMainImage = styled.img`
  height: 263px;
  align-self: stretch;
`

const CampCounterBox = styled(Box)`
  width: 90% !important;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  border-top: 1px solid ${colors.brandGray400};
  border-bottom: 1px solid ${colors.brandGray400};

  @media${({ theme }) => theme.device['sm']} {
    width: 75% !important;
  }
`

const StyledTitle = styled.p`
  font-family: 'Ubuntu', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  letter-spacing: -0.09375rem;
  color: ${colors.brandGray900};
  text-align: center;
  margin-bottom: 2px;
`

const Counter = styled.span`
  text-align: center;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.brandGray900};
  line-height: 1.5rem;
`

const FromText = styled.span`
  text-align: center;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.brandGray900};
  line-height: 1.5rem;
  margin-top: 0.5rem;
`
