import { Grid, Skeleton } from '@mui/material'
import { FC } from 'react'

const ChooseMetroSkeleton: FC = () => {
  const skeletons = [1, 2, 3, 4, 5]

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 3 }} rowSpacing={{ xs: 1, sm: 3 }}>
      {skeletons.map((_, index) => (
        <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
          <Skeleton variant="rectangular" height={447} />
        </Grid>
      ))}
    </Grid>
  )
}
export default ChooseMetroSkeleton
